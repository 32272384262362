import * as React from 'react';
const Dots = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={33}
    viewBox="0 0 20 33"
    fill="none"
    {...props}
  >
    <path
      d="M10 18C11.1046 18 12 17.1046 12 16C12 14.8954 11.1046 14 10 14C8.89543 14 8 14.8954 8 16C8 17.1046 8.89543 18 10 18Z"
      fill="inherit"
    />
    <path
      d="M18 18C19.1046 18 20 17.1046 20 16C20 14.8954 19.1046 14 18 14C16.8954 14 16 14.8954 16 16C16 17.1046 16.8954 18 18 18Z"
      fill="inherit"
    />
    <path
      d="M2 18C3.10457 18 4 17.1046 4 16C4 14.8954 3.10457 14 2 14C0.89543 14 0 14.8954 0 16C0 17.1046 0.89543 18 2 18Z"
      fill="inherit"
    />
  </svg>
);
export default Dots;
