const AUTH_KEY = 'ADMIN_AUTH';
const AUTH_REFRESH_KEY = 'AUTH_REFRESH';
const STORAGE_KEY = 'ADMIN_SIGNUP_CREDS_1';
const USER_ID = 'TGS_ADMIN_USER_ID';

// LOCAL STORAGE set Auth data
export const setAuth = (token) => {
  if (!localStorage) return false;
  localStorage.setItem(AUTH_KEY, token);
  return true;
};
// LOCAL STORAGE SET REFRESH TOKEN
export const setRefreshAuth = (token) => {
  if (!localStorage) return false;
  localStorage.setItem(AUTH_REFRESH_KEY, token);
  return true;
};

// LOCAL STORAGE set Auth data
export const getAuth = () => {
  if (!localStorage) return false;
  const token = localStorage.getItem(AUTH_KEY);
  if (token === 'undefined' || token === 'null' || !token) return null;
  return token;
};

// LOCAL STORAGE get refresh token
export const getRefreshAuth = () => {
  if (!localStorage) return false;
  const token = localStorage.getItem(AUTH_REFRESH_KEY);
  if (token === 'undefined' || token === 'null' || !token) return null;
  return token;
};

// LOCAL STORAGE remove Auth data
export const removeAuth = () => {
  if (localStorage) return localStorage.removeItem(AUTH_KEY);
};

// LOCAL STORAGE remove Refresh token
export const removeRefreshAuth = () => {
  if (localStorage) return localStorage.removeItem(AUTH_REFRESH_KEY);
};

// LOCAL STORAGE get Signup creds
export const getCredsFromtStorage = () => {
  if (!localStorage) return null;
  const creds = localStorage.getItem(STORAGE_KEY);
  if (!creds) return null;
  const data = JSON.parse(creds);
  const parseCreds = {
    email: data?.email,
    password: atob(data?.password)
  };
  return parseCreds;
};

// LOCAL STORAGE set Signup creds
export const setCredsToStorage = (creds) => {
  if (!localStorage) return null;
  creds = {
    email: creds.email,
    password: btoa(creds.password)
  };
  localStorage.setItem(STORAGE_KEY, JSON.stringify(creds));
};

// LOCAL STORAGE remove Signup creds
export const removeSignupCreds = () => {
  if (!localStorage) return null;
  localStorage.removeItem(STORAGE_KEY);
};

// LOCAL STORAGE tgs user id
export const setTGSUserId = (id) => {
  if (!localStorage) return null;
  id = btoa(id);
  localStorage.setItem(USER_ID, JSON.stringify(id));
};

// LOCAL STORAGE tgs user id
export const getTGSUserId = () => {
  if (!localStorage) return null;
  const id = atob(localStorage.getItem(USER_ID));
  return JSON.parse(id);
};

// LOCAL STORAGE remove tgs user id
export const removeTGSUserId = () => {
  if (!localStorage) return null;
  localStorage.removeItem(USER_ID);
};

export const storageRequest = {
  setAuth,
  getAuth,
  removeAuth,
  getCredsFromtStorage,
  setCredsToStorage,
  removeSignupCreds,
  getRefreshAuth,
  removeRefreshAuth,
  setTGSUserId,
  getTGSUserId,
  removeTGSUserId
};
