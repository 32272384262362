import * as React from 'react';
const LessThan = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={6}
    height={10}
    viewBox="0 0 6 10"
    fill="none"
    {...props}
  >
    <path
      id="Vector"
      d="M5 1L1 5L5 9"
      stroke="black"
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default LessThan;
