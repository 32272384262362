import * as React from 'react';

const Search = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    {...props}
  >
    <g id="Search  Icon">
      <path
        id="Vector"
        d="M7.70952 14.6362C11.5251 14.6362 14.6183 11.5836 14.6183 7.81811C14.6183 4.05257 11.5251 1 7.70952 1C3.89393 1 0.800781 4.05257 0.800781 7.81811C0.800781 11.5836 3.89393 14.6362 7.70952 14.6362Z"
        stroke="#5C5F62"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M16.0001 16.0003L12.5918 12.6367"
        stroke="#5C5F62"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default Search;
