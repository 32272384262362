import { getAuth } from 'helpers/storageRequests';
import React from 'react';
import { Navigate } from '../../../node_modules/react-router-dom/dist/index';

const LoginGuard = ({ children }) => {
  const token = getAuth();
  let isLoggedIn;
  if (token) {
    isLoggedIn = true;
  } else {
    isLoggedIn = false;
  }
  if (isLoggedIn) {
    return (
      <Navigate
        to="/"
        replace
      />
    );
  }
  return children;
};

export default LoginGuard;
