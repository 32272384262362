import * as React from 'react';
const Right = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g id="icons">
      <path
        id="Vector"
        d="M20 6L9 17L4 12"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default Right;
