/**
 * @method convertTruncateText - add "..." with a limitation
 * @param {*} source
 * @param {*} size
 */
const convertTruncateText = (source, size) => {
  if (source !== null || source !== undefined) {
    return source.length > size ? source.slice(0, size - 1) + '…' : source;
  } else return source;
};

const formatDate = (date) => {
  const handleDate = new Date(date);

  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric'
  });
  return dateTimeFormat.format(handleDate);
};
// YYYY-MM-DD
function formatD(date) {
  const curr = new Date(date);
  const year = curr.getFullYear();
  const month = String(curr.getMonth() + 1).padStart(2, '0');
  const day = String(curr.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const getCurrentMonthDates = () => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return { firstDay: formatD(firstDay), lastDay: formatD(lastDay) };
};

const getCurrentWeekDates = () => {
  const date = new Date();
  const currentDay = date.getDay(); // 0 (Sunday) to 6 (Saturday)

  // Calculate the number of days to subtract from the current date to get to the start of the week (Monday)
  const daysToSubtract = currentDay === 0 ? 6 : currentDay - 1;
  const startOfWeek = new Date(date);
  startOfWeek.setDate(date.getDate() - daysToSubtract);

  // Calculate the end of the week (Sunday)
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  return {
    firstDay: formatD(startOfWeek),
    lastDay: formatD(endOfWeek)
  };
};

const getCurrentYearDates = () => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), 0, 1); // January 1st of the current year
  const lastDay = new Date(date.getFullYear(), 11, 31); // December 31st of the current year

  return { firstDay: formatD(firstDay), lastDay: formatD(lastDay) };
};

const getCurrentQuarterDates = () => {
  const date = new Date();
  const currentMonth = date.getMonth();
  let startMonth, endMonth;

  if (currentMonth >= 0 && currentMonth <= 2) {
    // First quarter (January - March)
    startMonth = 0;
    endMonth = 2;
  } else if (currentMonth >= 3 && currentMonth <= 5) {
    // Second quarter (April - June)
    startMonth = 3;
    endMonth = 5;
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    // Third quarter (July - September)
    startMonth = 6;
    endMonth = 8;
  } else {
    // Fourth quarter (October - December)
    startMonth = 9;
    endMonth = 11;
  }

  const firstDay = new Date(date.getFullYear(), startMonth, 1);
  const lastDay = new Date(date.getFullYear(), endMonth + 1, 0);

  return { firstDay: formatD(firstDay), lastDay: formatD(lastDay) };
};

const showErrors = (error) => {
  if (typeof error === 'object') {
    const errorsText = Object.values(error)?.map((item, i) => `${item[0]}`);
    return (
      <>
        {errorsText.map((item, i) => (
          <div>{`${item}`}</div>
        ))}
      </>
    );
  }
};

export {
  formatDate,
  convertTruncateText,
  getCurrentMonthDates,
  getCurrentWeekDates,
  getCurrentYearDates,
  getCurrentQuarterDates,
  showErrors
};
