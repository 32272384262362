import React, { useState } from 'react';
import ConfirmationDialog from './ConfirmationDialog';
import * as storage from 'helpers/storageRequests';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logoutUser } from 'helpers/apiRequests';
import { activeItem } from 'store/reducers/menu';
import { setUserDetails } from 'store/reducers/user';

const LogoutConfirmation = ({ isOpenModal, setOpenModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = (status) => {
    if (status === true) {
      handelLogout();
    } else {
      setOpenModal(false);
    }
  };

  const handelLogout = async () => {
    try {
      setIsLoading(true);
      const response = await logoutUser();
      if (response?.data.status === 200) {
        storage.removeAuth();
        storage.removeRefreshAuth();
        storage.removeTGSUserId();
        navigate('/login');
        dispatch(activeItem({ openItem: [''] }));
        dispatch(setUserDetails({ userDetails: null }));
        toast.success('Logout successful.');
      }
    } catch (error) {
      if (error.response?.status === 401) {
        storage.removeAuth();
        storage.removeRefreshAuth();
        storage.removeTGSUserId();
        navigate('/login');
        toast.success('Logout successful.');
        setTimeout(() => {
          dispatch(setUserDetails({ userDetails: null }));
          dispatch(activeItem({ openItem: [''] }));
        }, 500);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <ConfirmationDialog
        isOpenModal={isOpenModal}
        setOpenModal={setOpenModal}
        handleClose={handleClose}
        data={{
          title: 'Logout',
          description: 'Are you sure you want to logout?',
          buttonTitle: 'Logout'
        }}
        isLoading={isLoading}
      />
    </div>
  );
};

export default LogoutConfirmation;
