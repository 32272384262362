import * as React from 'react';
const Logout = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M10 2.00049H12.6666C13.0202 2.00049 13.3593 2.14096 13.6093 2.391C13.8594 2.64104 13.9999 2.98016 13.9999 3.33377V12.6668C13.9999 13.0204 13.8594 13.3595 13.6093 13.6095C13.3593 13.8596 13.0202 14.0001 12.6666 14.0001H10"
      stroke="inherit"
      strokeWidth={1.83993}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66797 11.3334L10.0012 8.00021L6.66797 4.66699"
      stroke="inherit"
      strokeWidth={1.83993}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99971 8H2"
      stroke="inherit"
      strokeWidth={1.83993}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Logout;
