import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'components/guards/AuthGuard';
import { Navigate } from 'react-router-dom';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// tsr user
const TSRUserList = Loadable(lazy(() => import('pages/TSRUserList')));
const TRSUserForm = Loadable(lazy(() => import('pages/TRSUserForm')));

// companies
const CompanyList = Loadable(lazy(() => import('pages/company-list')));
const CompanyDetails = Loadable(lazy(() => import('pages/company-detail')));
const CompanyAdd = Loadable(lazy(() => import('pages/company-add')));
const CompanyEdit = Loadable(lazy(() => import('pages/company-edit')));
const CompanyUserAdd = Loadable(lazy(() => import('pages/company-user-add')));
const Help = Loadable(lazy(() => import('pages/Help')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/',
      element: (
        <Navigate
          to="/dashboard"
          replace
        />
      )
    },
    {
      path: '/dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'users',
      element: <TSRUserList />
    },
    {
      path: 'users/add',
      element: <TRSUserForm />
    },
    {
      path: 'users/edit/:id',
      element: <TRSUserForm />
    },
    {
      path: 'companies',
      element: <CompanyList />
    },
    {
      path: 'companies/:id',
      element: <CompanyDetails />
    },
    {
      path: 'companies/add',
      element: <CompanyAdd />
    },
    // {
    //   path: 'companies/edit/:id',
    //   element: <CompanyEdit />
    // },
    {
      path: 'companies/edit/:id',
      element: <CompanyEdit />
    },
    {
      path: 'companies/:companyId/user/add',
      element: <CompanyUserAdd />
    },
    {
      path: 'companies/:companyId/user/edit/:userId',
      element: <CompanyUserAdd />
    },
    {
      path: 'help',
      element: <Help />
    },
    {
      path: '*',
      element: <Navigate to="/" />
    }
  ]
};

export default MainRoutes;
