// ==============================|| OVERRIDES - PAGINATION ||============================== //

export default function Pagination(theme) {
  return {
    MuiPagination: {
      styleOverrides: {
        standard: {
          background: 'transparent'
        },
        outlined: {
          background: 'transparent'
        },
        root: {
          '&.MuiPagination-outlined': {
            // background: 'red'
            '.MuiPagination-ul': {
              '.MuiButtonBase-root': {
                borderColor: '#bababa',
                color: '#bababa',
                borderRadius: '6px',
                '&.Mui-selected': {
                  border: '1px solid #262626',
                  background: 'transparent',
                  color: '#262626'
                }
              }
            }
          }
        }
        //   standard: {
        //     minWidth: theme.spacing(2),
        //     height: theme.spacing(2),
        //     padding: theme.spacing(0.5)
        //   }
      }
    }
  };
}
