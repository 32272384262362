// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      url: '/',
      icon: 'HomeIcon'
    },
    {
      id: 'user',
      title: 'TSR Users',
      type: 'item',
      url: '/users',
      icon: 'UserIcon'
    },
    {
      id: 'companies',
      title: 'Companies',
      type: 'item',
      url: '/companies',
      icon: 'CompanyIcon'
    }
    // {
    //   id: 'report',
    //   title: 'Reports',
    //   type: 'item',
    //   url: '/reports',
    //   icon: 'ReportIcon'
    // }
  ]
};

export default dashboard;
