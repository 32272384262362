import * as React from 'react';
const Location = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={18}
    viewBox="0 0 17 18"
    fill="none"
    {...props}
  >
    <path
      d="M14.666 7.66675C14.666 12.3334 8.66602 16.3334 8.66602 16.3334C8.66602 16.3334 2.66602 12.3334 2.66602 7.66675C2.66602 6.07545 3.29816 4.54933 4.42337 3.42411C5.54859 2.29889 7.07472 1.66675 8.66602 1.66675C10.2573 1.66675 11.7834 2.29889 12.9087 3.42411C14.0339 4.54933 14.666 6.07545 14.666 7.66675Z"
      stroke="inherit"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66602 9.66675C9.77059 9.66675 10.666 8.77132 10.666 7.66675C10.666 6.56218 9.77059 5.66675 8.66602 5.66675C7.56145 5.66675 6.66602 6.56218 6.66602 7.66675C6.66602 8.77132 7.56145 9.66675 8.66602 9.66675Z"
      stroke="inherit"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Location;
