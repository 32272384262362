import * as React from 'react';
const Activity = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M14.6649 8.00027H11.9983L9.99839 14.0001L5.99853 2.00049L3.9986 8.00027H1.33203"
      stroke="inherit"
      strokeWidth={1.83993}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Activity;
