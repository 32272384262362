import * as React from 'react';
const Edit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <g
      id="icons"
      clipPath="url(#clip0_635_2517)"
    >
      <path
        id="Vector"
        d="M7.33398 3.16699H2.66732C2.3137 3.16699 1.97456 3.30747 1.72451 3.55752C1.47446 3.80756 1.33398 4.1467 1.33398 4.50033V13.8337C1.33398 14.1873 1.47446 14.5264 1.72451 14.7765C1.97456 15.0265 2.3137 15.167 2.66732 15.167H12.0007C12.3543 15.167 12.6934 15.0265 12.9435 14.7765C13.1935 14.5264 13.334 14.1873 13.334 13.8337V9.16699"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M12.334 2.16714C12.5992 1.90193 12.9589 1.75293 13.334 1.75293C13.7091 1.75293 14.0688 1.90193 14.334 2.16714C14.5992 2.43236 14.7482 2.79207 14.7482 3.16714C14.7482 3.54222 14.5992 3.90193 14.334 4.16714L8.00065 10.5005L5.33398 11.1671L6.00065 8.50048L12.334 2.16714Z"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_635_2517">
        <rect
          width={16}
          height={16}
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Edit;
