import * as React from 'react';
const Star = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    {...props}
  >
    <path
      d="M6.99728 1.56543L8.67663 4.9676L12.4321 5.51652L9.71467 8.16326L10.356 11.9024L6.99728 10.1361L3.63859 11.9024L4.27989 8.16326L1.5625 5.51652L5.31793 4.9676L6.99728 1.56543Z"
      fill="inherit"
      stroke="inherit"
      strokeWidth={0.869565}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Star;
