import * as React from 'react';
const Users = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M10.667 13.9999V12.6666C10.667 11.9594 10.386 11.2811 9.88596 10.781C9.38588 10.2809 8.70763 10 8.00041 10H4.00056C3.29334 10 2.61508 10.2809 2.11501 10.781C1.61493 11.2811 1.33398 11.9594 1.33398 12.6666V13.9999"
      stroke="inherit"
      strokeWidth={1.83993}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.00056 7.33314C7.47326 7.33314 8.66713 6.13928 8.66713 4.66657C8.66713 3.19386 7.47326 2 6.00056 2C4.52785 2 3.33398 3.19386 3.33398 4.66657C3.33398 6.13928 4.52785 7.33314 6.00056 7.33314Z"
      stroke="inherit"
      strokeWidth={1.83993}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6659 13.9991V12.6658C14.6655 12.075 14.4689 11.5011 14.1069 11.0341C13.7449 10.5672 13.2381 10.2336 12.666 10.0859"
      stroke="inherit"
      strokeWidth={1.06663}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.666 2.08594C11.2396 2.2328 11.748 2.56639 12.1111 3.03411C12.4741 3.50183 12.6712 4.07709 12.6712 4.66918C12.6712 5.26127 12.4741 5.83652 12.1111 6.30425C11.748 6.77197 11.2396 7.10556 10.666 7.25242"
      stroke="inherit"
      strokeWidth={1.06663}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Users;
