import * as React from 'react';
const Mail = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M13.332 2.66699H2.66536C1.92898 2.66699 1.33203 3.26395 1.33203 4.00033V12.0003C1.33203 12.7367 1.92898 13.3337 2.66536 13.3337H13.332C14.0684 13.3337 14.6654 12.7367 14.6654 12.0003V4.00033C14.6654 3.26395 14.0684 2.66699 13.332 2.66699Z"
      stroke="inherit"
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6654 4.66699L8.68536 8.46699C8.47955 8.59594 8.24158 8.66433 7.9987 8.66433C7.75582 8.66433 7.51785 8.59594 7.31203 8.46699L1.33203 4.66699"
      stroke="inherit"
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Mail;
