import React from 'react';
import { Box, Divider, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { CrossIcon } from 'assets/svgs/index';
import PropTypes from 'prop-types';

const ConfirmationDialog = ({ isOpenModal, setOpenModal, handleClose, data }) => {
  return (
    <Dialog
      open={Boolean(isOpenModal)}
      onClose={() => setOpenModal(false)}
      maxWidth={'xs'}
    >
      <Box sx={{ padding: '14px 28px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <DialogTitle sx={{ fontSize: '18px', padding: '0px' }}>{data?.title}</DialogTitle>
        <Box
          onClick={() => setOpenModal(false)}
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          <CrossIcon stroke="#5C5F62" />
        </Box>
      </Box>
      <Divider sx={{ mx: 3 }} />
      <DialogContent sx={{ textAlign: 'center' }}>
        <DialogContentText>{data?.description}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: '24px 28px 24px' }}>
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={() => handleClose(false)}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleClose(true)}
          fullWidth
          variant="contained"
          color="primary"
        >
          Yes, {data?.buttonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    buttonTitle: PropTypes.string
  })
};

export default ConfirmationDialog;
