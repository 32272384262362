// material-ui
import { useMediaQuery, Link, Typography, Stack, Divider } from '@mui/material';
import { Container } from '../../../node_modules/@mui/material/index';

// ==============================|| FOOTER - AUTHENTICATION ||============================== //

const AuthFooter = () => {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Divider />
      <Container maxWidth="xl">
        <Stack
          direction={matchDownSM ? 'column' : 'row'}
          justifyContent={matchDownSM ? 'center' : 'space-between'}
          spacing={2}
          textAlign={matchDownSM ? 'center' : 'inherit'}
          sx={{ py: '24px' }}
        >
          <Typography
            variant="h6"
            href=""
            target=""
            underline="none"
          ></Typography>

          <Stack
            direction={matchDownSM ? 'column' : 'row'}
            spacing={matchDownSM ? 1 : 3}
            textAlign={matchDownSM ? 'center' : 'inherit'}
          >
            <Typography
              variant="subtitle1"
              color="secondary"
            >
              © 2023 Turnkey Strategic Relations, LLC.
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default AuthFooter;
