import * as React from 'react';
const Delete = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1206_7895)">
      <path
        d="M2 4.5H14"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6673 4.5V13.8333C12.6673 14.5 12.0007 15.1667 11.334 15.1667H4.66732C4.00065 15.1667 3.33398 14.5 3.33398 13.8333V4.5"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33398 4.50065V3.16732C5.33398 2.50065 6.00065 1.83398 6.66732 1.83398H9.33398C10.0007 1.83398 10.6673 2.50065 10.6673 3.16732V4.50065"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66602 7.83398V11.834"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33398 7.83398V11.834"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1206_7895">
        <rect
          width={16}
          height={16}
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Delete;
