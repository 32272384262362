import { REACT_APP_ADMIN_API_ENDPOINT } from 'constant/AppConfig';
import { refreshTokenUrl } from 'constant/urls';
import * as storage from 'helpers/storageRequests';
import { axiosInstance } from 'plugin/axios';
import { toast } from 'react-toastify';

const API_ENDPOINT = REACT_APP_ADMIN_API_ENDPOINT;

export const refreshTokenApi = async () => {
  try {
    // import your accessToken here
    let accessToken = storage.getRefreshAuth();
    let response = await axiosInstance.post(`${API_ENDPOINT}${refreshTokenUrl}`, {
      refresh_token: accessToken
    });
    if (response.data?.data?.access_token) {
      storage.setAuth(response.data?.data?.access_token);
      //   document.cookie = 'auth._token.Bearer=' + response.data?.data?.token + '; expires=' + now.toGMTString() + '; path=/';
    }
    return response;
  } catch (err) {
    if (err.response.data.error === 'UNAUTHORIZED' || err.status === 401) {
      window.location.href = '/login';
      storage.removeAuth();
      storage.removeRefreshAuth();
      storage.removeTGSUserId();
      toast.error(err.response.data.message);
    }
    return err;
  }
};
