import * as React from 'react';
const Slack = (props) => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 0.36 0.36"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <g
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        fill="#E01E5A"
        d="M0.056 0.255a0.033 0.033 0 0 0 0.033 -0.033v-0.033h-0.033A0.033 0.033 0 0 0 0.022 0.222a0.033 0.033 0 0 0 0.033 0.033zm0.083 -0.066a0.033 0.033 0 0 0 -0.033 0.033v0.083c0 0.018 0.015 0.033 0.033 0.033a0.033 0.033 0 0 0 0.033 -0.033V0.222a0.033 0.033 0 0 0 -0.033 -0.033"
      />
      <path
        fill="#36C5F0"
        d="M0.105 0.056a0.033 0.033 0 0 0 0.033 0.033h0.033v-0.033A0.033 0.033 0 0 0 0.138 0.022a0.033 0.033 0 0 0 -0.033 0.033zm0.066 0.083a0.033 0.033 0 0 0 -0.033 -0.033H0.056A0.033 0.033 0 0 0 0.022 0.138c0 0.018 0.015 0.033 0.033 0.033h0.083a0.033 0.033 0 0 0 0.033 -0.033z"
      />
      <path
        fill="#2EB67D"
        d="M0.222 0.172a0.033 0.033 0 0 0 0.033 -0.033V0.056A0.033 0.033 0 0 0 0.222 0.022a0.033 0.033 0 0 0 -0.033 0.033v0.083c0 0.018 0.015 0.033 0.033 0.033zm0.083 -0.066a0.033 0.033 0 0 0 -0.033 0.033v0.033h0.033A0.033 0.033 0 0 0 0.337 0.138a0.033 0.033 0 0 0 -0.033 -0.033z"
      />
      <path
        fill="#ECB22E"
        d="M0.188 0.222a0.033 0.033 0 0 0 0.033 0.033h0.083A0.033 0.033 0 0 0 0.337 0.222a0.033 0.033 0 0 0 -0.033 -0.033H0.222a0.033 0.033 0 0 0 -0.033 0.033zm0.066 0.083a0.033 0.033 0 0 0 -0.033 -0.033h-0.033v0.033a0.033 0.033 0 0 0 0.033 0.033 0.033 0.033 0 0 0 0.033 -0.033"
      />
    </g>
  </svg>
);
export default Slack;
