import axios from 'axios';
import { loginUrl, refreshTokenUrl } from 'constant/urls';
import { refreshTokenApi } from 'apiService/auth';
import { getAuth } from 'helpers/storageRequests';
import * as storage from 'helpers/storageRequests';
import { toast } from 'react-toastify';
import { REACT_APP_ADMIN_API_ENDPOINT } from 'constant/AppConfig';
import { showErrors } from 'utils/common';

const ADMIN_ENDPOINT = REACT_APP_ADMIN_API_ENDPOINT;
// define API_URL and APP ID in env file
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_API_URL || 'http://127.0.0.1:8000'
});
axiosInstance.interceptors.request.use((config) => {
  const token = getAuth();
  // const BearerToken = token ? token : null;
  // config.headers.Authorization = `Bearer ${BearerToken}`;
  // config.headers.appid = process.env.APP_ID;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.data?.meta_data?.error_fields) {
      toast.error(showErrors(error?.response?.data?.meta_data?.error_fields), {
        autoClose: 6000
      });
    }
    const originalRequest = error.config;
    if (originalRequest.url !== loginUrl && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('ADMIN_AUTH');
      if (originalRequest.url === `${ADMIN_ENDPOINT}${refreshTokenUrl}`) {
        window.location.href = '/login';
        storage.removeAuth();
        storage.removeRefreshAuth();
        storage.removeTGSUserId();
        toast.error(error.response.data.message);
      } else if (refreshToken) {
        try {
          const response = await refreshTokenApi();
          axios.defaults.headers.common.Authorization = `Bearer ${response.data?.data?.access_token}`;
          return axiosInstance(originalRequest);
        } catch (error) {
          console.error(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

// const responseErrorInterceptor = (error) => {
//   const errResponse = error.response;
//   if (errResponse && errResponse.status === 401 && errResponse.data) {
//     const token = storageRequest.getAuth();
//     if (token) {
//       toast.success(
//         "Your session has expired. Please login again to continue working"
//       );
//     }
//     storageRequest.removeAuth();
//     window.location.href = "/login";
//     return Promise.reject(error);
//   }
//   return Promise.reject(error);
// };
