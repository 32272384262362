import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { ListItemButton, ListItemIcon, SvgIcon, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem, setActiveMenu } from 'store/reducers/menu';
import * as svgICon from 'assets/svgs';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level, setOpenLogoutModal }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { drawerOpen, openItem } = useSelector((state) => state.menu);

  // const handelLogout = async () => {
  //   try {
  //     const response = await logoutUser();
  //     if (response?.data.status === 200) {
  //       storage.removeAuth();
  //       storage.removeRefreshAuth();
  //       storage.removeTGSUserId();
  //       //window.location.href = '/login';
  //       navigate('/login');
  //       toast.success('Logout successful');
  //     }
  //   } catch (error) {
  //     if (error.response?.status === 401) {
  //       storage.removeAuth();
  //       storage.removeRefreshAuth();
  //       storage.removeTGSUserId();
  //       //window.location.href = '/login';
  //       navigate('/login');
  //       toast.success('Logout successful');
  //     }
  //   }
  // };

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <>
        {item.url === 'logout' ? (
          <Link
            ref={ref}
            {...props}
            onClick={() => {
              setOpenLogoutModal(true);
            }}
          />
        ) : (
          <Link
            ref={ref}
            {...props}
            to={item.url}
            target={itemTarget}
          />
        )}
      </>
    ))
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    dispatch(activeItem({ openItem: [id] }));
  };

  const Icon = svgICon[item.icon];
  const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '24px' : '24px' }} /> : false;

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;
  // active menu item on page load
  useEffect(() => {
    if (pathname.includes(item.url)) {
      dispatch(activeItem({ openItem: [item.id] }));
      dispatch(setActiveMenu({ activeMenu: item?.title === 'Home' ? 'Dashboard' : item?.title }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = theme.palette.secondary.light;
  const iconSelectedColor = theme.palette.common.white;

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      onClick={() => itemHandler(item.id)}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        width: drawerOpen ? '196px' : 'auto',
        justifyContent: !drawerOpen ? 'center' : 'start',
        margin: '8px auto ',
        borderRadius: '12px',
        // textAlign: 'center',
        pl: drawerOpen ? `${level * 12}px` : 1.5,
        py: !drawerOpen && level === 1 ? '0px' : '7.5px',
        ...(drawerOpen && {
          '&:hover': {
            bgcolor: 'primary.lighter'
            // color: ''
          },
          '&.Mui-selected': {
            bgcolor: 'primary.main',
            // borderRight: `2px solid ${theme.palette.primary.main}`,
            color: iconSelectedColor,
            '&:hover': {
              color: iconSelectedColor,
              bgcolor: 'primary.main'
            }
          }
        }),
        ...(!drawerOpen && {
          '&:hover': {
            bgcolor: 'transparent'
          },
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: 'transparent'
            },
            bgcolor: 'transparent'
          }
        })
      }}
    >
      {Boolean(itemIcon) && (
        <ListItemIcon
          sx={{
            svg: {
              stroke: isSelected ? iconSelectedColor : textColor,
              height: '24px',
              width: '24px'
            },
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            ...(!drawerOpen && {
              borderRadius: '12px',
              width: 48,
              height: 48,
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                bgcolor: 'secondary.lighter'
              }
            }),
            ...(!drawerOpen &&
              isSelected && {
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: 'primary.lighter'
                }
              })
          }}
        >
          {Icon && <Icon />}
        </ListItemIcon>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography
              variant="h6"
              sx={{ marginLeft: '16px', color: isSelected ? iconSelectedColor : textColor, fontWeight: 500 }}
            >
              {item.title}
            </Typography>
          }
        />
      )}
      {/* {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )} */}
    </ListItemButton>
  );
};

// NavItem.propTypes = {
//   item: PropTypes.object,
//   level: PropTypes.number
// };

export default NavItem;
