import * as React from 'react';
const Archive = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M13.334 2.66602H2.66732C1.93094 2.66602 1.33398 3.26297 1.33398 3.99935V4.66602C1.33398 5.4024 1.93094 5.99935 2.66732 5.99935H13.334C14.0704 5.99935 14.6673 5.4024 14.6673 4.66602V3.99935C14.6673 3.26297 14.0704 2.66602 13.334 2.66602Z"
      stroke="inherit"
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.66602 6V12C2.66602 12.3536 2.80649 12.6928 3.05654 12.9428C3.30659 13.1929 3.64573 13.3333 3.99935 13.3333H11.9993C12.353 13.3333 12.6921 13.1929 12.9422 12.9428C13.1922 12.6928 13.3327 12.3536 13.3327 12V6"
      stroke="inherit"
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66602 8.66602H9.33268"
      stroke="inherit"
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Archive;
