import * as React from 'react';

const User = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M18.9992 21V19C18.9992 17.9391 18.5778 16.9217 17.8276 16.1716C17.0775 15.4214 16.0601 15 14.9992 15H8.99921C7.93834 15 6.92092 15.4214 6.17078 16.1716C5.42063 16.9217 4.99921 17.9391 4.99921 19V21"
      stroke="inherit"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9992 11C14.2083 11 15.9992 9.20914 15.9992 7C15.9992 4.79086 14.2083 3 11.9992 3C9.79007 3 7.99921 4.79086 7.99921 7C7.99921 9.20914 9.79007 11 11.9992 11Z"
      stroke="inherit"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default User;
