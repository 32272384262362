import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import MinimalRoutes from './MinimalRoutes';
import { Navigate } from 'react-router-dom';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    LoginRoutes,
    MinimalRoutes,
    {
      path: '*',
      element: <Navigate to="/" />
    }
  ]);
}
