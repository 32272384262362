import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import LoginGuard from 'components/guards/LoginGuard';
import { Navigate } from 'react-router-dom';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: '',
  children: [
    // {
    //   path: '/',
    //   element: (
    //     <Navigate
    //       to="/admin"
    //       replace
    //     />
    //   )
    // },
    {
      path: 'login',
      element: (
        <LoginGuard>
          <AuthLogin />
        </LoginGuard>
      )
    },
    {
      path: '*',
      element: <Navigate to="/" />
    }
  ]
};

export default LoginRoutes;
