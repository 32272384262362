// types
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequest } from 'helpers/apiRequests';

// initial state
const initialState = {
  userDetails: null,
  isLoading: false
};

// ==============================|| SLICE - USER ||============================== //

const fetchUserDetails = createAsyncThunk('users/id', async (id, thunkAPI) => {
  try {
    const { data } = await apiRequest.getUserDetails(id);
    if (!data.ok) throw data?.message;
    return data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails(state, action) {
      state.userDetails = action.payload.userDetails;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.userDetails = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        console.log('Error getting user data', action.payload);
        state.isLoading = false;
      });
  }
});

export { fetchUserDetails };
export default user.reducer;

export const { setUserDetails } = user.actions;
