const Calendar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={15}
    viewBox="0 0 14 15"
    fill="none"
    {...props}
  >
    <g opacity={1}>
      <path
        d="M11.0833 2.83301H2.91667C2.27233 2.83301 1.75 3.35534 1.75 3.99967V12.1663C1.75 12.8107 2.27233 13.333 2.91667 13.333H11.0833C11.7277 13.333 12.25 12.8107 12.25 12.1663V3.99967C12.25 3.35534 11.7277 2.83301 11.0833 2.83301Z"
        stroke="inherit"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33398 1.66699V4.00033"
        stroke="inherit"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66602 1.66699V4.00033"
        stroke="inherit"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 6.33301H12.25"
        stroke="inherit"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default Calendar;
