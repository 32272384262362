// material-ui
import { Stack, Typography, Box, Avatar } from '@mui/material';

// assets
import { useTheme } from '@mui/material/styles';
import { TableLogoIcon } from 'assets/svgs/index';
import { useSelector } from 'react-redux';

// ==============================|| DRAWER CONTENT - NAVIGATION CARD ||============================== //
function stringAvatar(name) {
  return {
    sx: {
      bgcolor: 'secondary'
    },
    children: `${name.split(' ')?.[0]?.[0].toUpperCase()}${name.split(' ')?.[1]?.[0]}`
  };
}

const NavCard = () => {
  const menu = useSelector((state) => state.menu);
  const user = useSelector((state) => state.user.userDetails);
  const { drawerOpen } = menu;
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      spacing={2.5}
      sx={{ my: '32px' }}
    >
      <Stack alignItems="center">
        <Box
          sx={{
            height: '63px',
            width: '63px',
            borderRadius: '50%',
            // background: theme.palette.primary.light,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: '10px'
          }}
        >
          {user?.profile_image?.length > 1 ? (
            <Avatar
              src={user?.profile_image}
              alt={`${user?.first_name[0].toUpperCase()} ${user?.last_name[0]}`}
              sx={{ bgcolor: 'secondary', fontSize: '15px', color: 'black', width: 63, height: 63 }}
            />
          ) : (
            <Avatar
              {...stringAvatar(`${user?.first_name[0].toUpperCase()} ${user?.last_name[0]}`)}
              // alt={stringAvatar(`${row?.name}`)}
              sx={{ bgcolor: 'secondary', fontSize: '15px', color: 'black', width: 63, height: 63 }}
            />
          )}
          {/* <TableLogoIcon /> */}
        </Box>
        {drawerOpen && (
          <>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500 }}
            >
              {user && `${user?.first_name} ${user?.last_name}`}
            </Typography>
            <Typography
              variant="body2"
              color={theme.palette.slate[400]}
            >
              {user?.email}
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default NavCard;
