import * as React from 'react';
const Plus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1803_7892)">
      <path
        d="M7.33398 1.33398C6.98036 1.33398 6.64122 1.47446 6.39118 1.72451C6.14113 1.97456 6.00065 2.3137 6.00065 2.66732V6.00065H2.66732C2.3137 6.00065 1.97456 6.14113 1.72451 6.39118C1.47446 6.64122 1.33398 6.98036 1.33398 7.33398V8.66732C1.33398 9.40065 1.93398 10.0007 2.66732 10.0007H6.00065V13.334C6.00065 14.0673 6.60065 14.6673 7.33398 14.6673H8.66732C9.02094 14.6673 9.36008 14.5268 9.61013 14.2768C9.86018 14.0267 10.0007 13.6876 10.0007 13.334V10.0007H13.334C13.6876 10.0007 14.0267 9.86018 14.2768 9.61013C14.5268 9.36008 14.6673 9.02094 14.6673 8.66732V7.33398C14.6673 6.98036 14.5268 6.64122 14.2768 6.39118C14.0267 6.14113 13.6876 6.00065 13.334 6.00065H10.0007V2.66732C10.0007 2.3137 9.86018 1.97456 9.61013 1.72451C9.36008 1.47446 9.02094 1.33398 8.66732 1.33398H7.33398Z"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1803_7892">
        <rect
          width={16}
          height={16}
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Plus;
