import { Outlet } from 'react-router-dom';
import { Box, Container, Grid } from '../../../node_modules/@mui/material/index';
import AuthFooter from 'components/cards/AuthFooter';
import Logo from 'components/Logo';

// ==============================|| MINIMAL LAYOUT ||============================== //

/**
 * MinimalLayout - Layout use for before login layout
 */

const MinimalLayout = () => (
  <>
    <Box sx={{ minHeight: '100vh', background: '#FBFBFB', position: 'relative' }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        // alignItems="center"
        sx={{
          minHeight: '100vh'
        }}
      >
        <Grid item xs={12} sx={{ ml: '', mt: '32px' }}>
          <Container maxWidth="xl">
            <Logo
              sx={{
                height: '40px',
                width: '178px',
                img: {
                  height: '40px',
                  width: '178px'
                }
              }}
              to=""
            />
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            // alignItems="center"
            sx={{ minHeight: { xs: 'calc(100vh - 154px)', md: 'calc(100vh - 154px)' } }}
          >
            <Grid item>
              {/* <Container maxWidth="xl"> */}
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                <Outlet />
              </Box>
              {/* </Container> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ m: 0, mt: 1 }}>
          <AuthFooter />
        </Grid>
      </Grid>
    </Box>
  </>
);

export default MinimalLayout;
