import * as React from 'react';
const Document = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1803_5997)">
      <path
        d="M9.66602 1.83301H3.99935C3.64573 1.83301 3.30659 1.97348 3.05654 2.22353C2.80649 2.47358 2.66602 2.81272 2.66602 3.16634V13.833C2.66602 14.1866 2.80649 14.5258 3.05654 14.7758C3.30659 15.0259 3.64573 15.1663 3.99935 15.1663H11.9993C12.353 15.1663 12.6921 15.0259 12.9422 14.7758C13.1922 14.5258 13.3327 14.1866 13.3327 13.833V5.49967L9.66602 1.83301Z"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33398 1.83301V5.83301H13.334"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6673 9.16699H5.33398"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6673 11.833H5.33398"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66732 6.5H5.33398"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1803_5997">
        <rect
          width={16}
          height={16}
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Document;
