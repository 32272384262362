import * as React from 'react';

const DownArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      d="M4.5 6L8.5 10L12.5 6"
      stroke="inherit"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DownArrow;
