import * as React from 'react';
const TableLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={39}
    height={39}
    viewBox="0 0 39 39"
    fill="none"
    {...props}
  >
    <g id="tabler-icon-cube">
      <g id="Vector">
        <path
          d="M33.8409 25.4188V12.6988C33.84 12.1453 33.6927 11.6018 33.4141 11.1235C33.1355 10.6451 32.7355 10.2489 32.2545 9.97493L21.1495 3.61651C20.6663 3.34049 20.1195 3.19531 19.563 3.19531C19.0066 3.19531 18.4598 3.34049 17.9766 3.61651L6.87159 9.97493C5.88959 10.5381 5.28516 11.5772 5.28516 12.7004V25.4204C5.28516 26.5452 5.88959 27.5827 6.87159 28.1443L17.9766 34.5027C18.4598 34.7788 19.0066 34.9239 19.563 34.9239C20.1195 34.9239 20.6663 34.7788 21.1495 34.5027L32.2545 28.1443C33.2365 27.5811 33.8409 26.542 33.8409 25.4188Z"
          fill="#B6281F"
        />
        <path
          d="M19.563 34.9239V19.0604V34.9239Z"
          fill="#B6281F"
        />
        <path
          d="M19.563 19.0604L33.4126 11.0647L19.563 19.0604Z"
          fill="#B6281F"
        />
        <path
          d="M5.71275 11.0647L19.563 19.0604L5.71275 11.0647Z"
          fill="#B6281F"
        />
      </g>
      <g id="Vector_2">
        <path
          d="M19.563 34.9265V19.062V34.9265Z"
          fill="#D9D9D9"
        />
        <path
          d="M19.563 19.062L33.4141 11.1251L19.563 19.062Z"
          fill="#D9D9D9"
        />
      </g>
      <g
        id="Vector 26"
        style={{
          mixBlendMode: 'color'
        }}
      >
        <path
          d="M33.8404 11.1289L19.5625 19.0611V35.7186L33.8404 26.9932V11.1289Z"
          fill="#97241D"
        />
      </g>
      <g
        id="Vector 27"
        style={{
          mixBlendMode: 'color'
        }}
      >
        <path
          d="M5.28461 11.1289L19.5625 19.0611V35.7186L5.28461 26.9932V11.1289Z"
          fill="#7D251F"
        />
      </g>
    </g>
  </svg>
);
export default TableLogo;
