import * as React from 'react';
const Company = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M18.0008 2.00037H6.00079C4.89622 2.00037 4.00079 2.8958 4.00079 4.00037V20.0004C4.00079 21.1049 4.89622 22.0004 6.00079 22.0004H18.0008C19.1054 22.0004 20.0008 21.1049 20.0008 20.0004V4.00037C20.0008 2.8958 19.1054 2.00037 18.0008 2.00037Z"
      stroke="inherit"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 22V18H15V22"
      stroke="inherit"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99921 6H8.00921"
      stroke="inherit"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0008 6H16.0108"
      stroke="inherit"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 6H12.01"
      stroke="inherit"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 9.99963H12.01"
      stroke="inherit"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 14.0004H12.01"
      stroke="inherit"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0008 9.99963H16.0108"
      stroke="inherit"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0008 14.0004H16.0108"
      stroke="inherit"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99921 9.99963H8.00921"
      stroke="inherit"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99921 14.0004H8.00921"
      stroke="inherit"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Company;
