import * as React from 'react';
const Clock = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_477_10364)">
      <path
        d="M6.66797 1.33398H9.33454"
        stroke="inherit"
        strokeWidth={1.83993}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 9.33342L9.99993 7.3335"
        stroke="inherit"
        strokeWidth={1.83993}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00111 14.6668C10.9465 14.6668 13.3343 12.279 13.3343 9.33363C13.3343 6.38822 10.9465 4.00049 8.00111 4.00049C5.0557 4.00049 2.66797 6.38822 2.66797 9.33363C2.66797 12.279 5.0557 14.6668 8.00111 14.6668Z"
        stroke="inherit"
        strokeWidth={1.83993}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_477_10364">
        <rect
          width={15.9994}
          height={15.9994}
          fill="inherit"
          transform="translate(0 0.000488281)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Clock;
