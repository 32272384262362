import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/Logo';
import logoIcon from 'assets/images/logoIcon.png';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    // only available in paid version
    <DrawerHeaderStyled
      theme={theme}
      open={open}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
      >
        {open ? (
          <Logo
            to="/"
            sx={{
              img: {
                height: '40px',
                width: '196px'
              }
            }}
          />
        ) : (
          <img
            src={logoIcon}
            alt="logo_icon"
          />
        )}
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
