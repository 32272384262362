import * as React from 'react';
const Restore = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="M13.334 3.16699H2.66732C1.93094 3.16699 1.33398 3.76395 1.33398 4.50033V5.16699C1.33398 5.90337 1.93094 6.50033 2.66732 6.50033H13.334C14.0704 6.50033 14.6673 5.90337 14.6673 5.16699V4.50033C14.6673 3.76395 14.0704 3.16699 13.334 3.16699Z"
      stroke="inherit"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9.16699V13.8337"
      stroke="inherit"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 11.167L8 9.16699L10 11.167"
      stroke="inherit"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.66602 6.5V12.5C2.66602 12.8536 2.80649 13.1928 3.05654 13.4428C3.30659 13.6929 3.64573 13.8333 3.99935 13.8333H5.33268"
      stroke="inherit"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3327 6.5V12.5C13.3327 12.8536 13.1922 13.1928 12.9422 13.4428C12.6921 13.6929 12.353 13.8333 11.9993 13.8333H10.666"
      stroke="inherit"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Restore;
