import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout/index';
import LoginGuard from 'components/guards/LoginGuard';
import { Navigate } from 'react-router-dom';
// import MinimalLayout from 'layout/MinimalLayout';

// render - login
const VerifyEmail = Loadable(lazy(() => import('pages/authentication/VerifyEmail')));
const ForgotPassword = Loadable(lazy(() => import('pages/authentication/forgot-password')));
const NewUserInvitation = Loadable(lazy(() => import('pages/authentication/new-user')));
// ==============================|| AUTH ROUTING ||============================== //

const MinimalRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'verify-otp',
      element: (
        <LoginGuard>
          <VerifyEmail />
        </LoginGuard>
      )
    },
    {
      path: 'forgot-password',
      element: (
        <LoginGuard>
          <ForgotPassword />
        </LoginGuard>
      )
    },
    {
      path: 'reset-password/:token',
      element: (
        <LoginGuard>
          <ForgotPassword />
        </LoginGuard>
      )
    },
    {
      path: 'new-user/:token',
      element: <NewUserInvitation />
    },
    {
      path: '*',
      element: <Navigate to="/" />
    }
  ]
};

export default MinimalRoutes;
