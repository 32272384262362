import * as React from 'react';
const Website = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M10.0013 18.3327C14.6037 18.3327 18.3346 14.6017 18.3346 9.99935C18.3346 5.39698 14.6037 1.66602 10.0013 1.66602C5.39893 1.66602 1.66797 5.39698 1.66797 9.99935C1.66797 14.6017 5.39893 18.3327 10.0013 18.3327Z"
      stroke="inherit"
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66797 10H18.3346"
      stroke="inherit"
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0013 1.66602C12.0857 3.94798 13.2703 6.90938 13.3346 9.99935C13.2703 13.0893 12.0857 16.0507 10.0013 18.3327C7.9169 16.0507 6.73234 13.0893 6.66797 9.99935C6.73234 6.90938 7.9169 3.94798 10.0013 1.66602Z"
      stroke="inherit"
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Website;
