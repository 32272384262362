// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors) => {
  const { red, gold, cyan, green, grey } = colors;
  const greyColors = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };

  const primaryRed = {
    100: '#FDE5E3',
    200: '#FDCECB',
    300: '#FAACA7',
    400: '#F67B73',
    500: '#EC5248',
    600: '#D93429',
    700: '#B6281F',
    800: '#97241D',
    900: '#7D251F',
    1000: '#440E0B'
  };

  const slateColor = {
    50: '#f8fafc',
    100: '#f1f5f9',
    200: '#e2e8f0',
    300: '#cbd5e1',
    400: '#94a3b8',
    500: '#64748b',
    600: '#475569',
    700: '#334155',
    800: '#1e293b',
    900: '#0f172a'
  };

  const greenColor = {
    400: '#E3EDD0',
    500: '#48562C'
  };

  // const secondaryGrey = {};
  const contrastText = '#fff';

  return {
    primary: {
      lighter: primaryRed[100],
      100: primaryRed[100],
      200: primaryRed[200],
      light: primaryRed[200],
      300: primaryRed[300],
      400: primaryRed[400],
      500: primaryRed[500],
      600: primaryRed[600],
      main: primaryRed[700],
      dark: primaryRed[800],
      700: primaryRed[700],
      800: primaryRed[800],
      darker: primaryRed[900],
      900: primaryRed[900],
      contrastText
    },
    secondary: {
      lighter: greyColors[100],
      100: greyColors[100],
      200: greyColors[200],
      light: '#818590',
      400: greyColors[400],
      main: '#5C5F62',
      600: greyColors[600],
      dark: greyColors[700],
      800: greyColors[800],
      darker: greyColors[900],
      A100: greyColors[0],
      A200: greyColors.A400,
      A300: greyColors.A700,
      contrastText: greyColors[0]
    },
    error: {
      lighter: red[0],
      light: red[2],
      main: '#D93429',
      dark: red[7],
      darker: red[9],
      contrastText
    },
    warning: {
      lighter: gold[0],
      light: gold[3],
      main: gold[5],
      dark: gold[7],
      darker: gold[9],
      contrastText: greyColors[100]
    },
    info: {
      lighter: cyan[0],
      light: cyan[3],
      main: cyan[5],
      dark: cyan[7],
      darker: cyan[9],
      contrastText
    },
    success: {
      lighter: green[0],
      light: '#0F9D714D',
      main: '#0F9D71',
      dark: green[7],
      darker: green[9],
      contrastText
    },
    grey: greyColors,
    slate: slateColor,
    green: {
      light: greenColor[400],
      main: greenColor[500]
    }
  };
};

export default Theme;
