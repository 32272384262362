import * as React from 'react';
const Send = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1353_764)">
      <path
        d="M14.6673 1.83398L7.33398 9.16732"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6673 1.83398L10.0007 15.1673L7.33398 9.16732L1.33398 6.50065L14.6673 1.83398Z"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1353_764">
        <rect
          width={16}
          height={16}
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Send;
