import * as React from 'react';
const Phone = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_363_17593)">
      <path
        d="M14.6689 11.2797V13.2797C14.6697 13.4654 14.6316 13.6492 14.5572 13.8193C14.4829 13.9894 14.3738 14.1421 14.237 14.2676C14.1001 14.3932 13.9386 14.4887 13.7627 14.5482C13.5869 14.6077 13.4005 14.6298 13.2156 14.6131C11.1641 14.3902 9.19357 13.6892 7.46223 12.5664C5.85145 11.5428 4.48579 10.1772 3.46223 8.56641C2.33555 6.82721 1.6344 4.84707 1.41557 2.78641C1.39891 2.60205 1.42082 2.41625 1.4799 2.24082C1.53898 2.0654 1.63395 1.9042 1.75874 1.76749C1.88354 1.63077 2.03544 1.52155 2.20476 1.44675C2.37409 1.37196 2.55713 1.33325 2.74223 1.33307H4.74223C5.06577 1.32989 5.37943 1.44446 5.62474 1.65543C5.87006 1.8664 6.03029 2.15937 6.07557 2.47974C6.15998 3.11978 6.31653 3.74822 6.54223 4.35307C6.63193 4.59169 6.65134 4.85102 6.59817 5.10033C6.545 5.34964 6.42148 5.57848 6.24223 5.75974L5.39557 6.60641C6.3446 8.27544 7.72654 9.65737 9.39557 10.6064L10.2422 9.75974C10.4235 9.5805 10.6523 9.45697 10.9016 9.4038C11.151 9.35063 11.4103 9.37004 11.6489 9.45974C12.2537 9.68544 12.8822 9.84199 13.5222 9.92641C13.8461 9.97209 14.1418 10.1352 14.3533 10.3847C14.5647 10.6343 14.677 10.9528 14.6689 11.2797Z"
        stroke="inherit"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_363_17593">
        <rect
          width={16}
          height={16}
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Phone;
