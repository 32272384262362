// material-ui
import { useEffect } from 'react';
import { Box, useMediaQuery, Typography, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import Notification from './Notification';
import MobileSection from './MobileSection';
import { TableLogoIcon } from 'assets/svgs/index';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import menuItems from 'menu-items/index';
import { setActiveMenu } from 'store/reducers/menu';

// ==============================|| HEADER - CONTENT ||============================== //

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: 'secondary'
    },
    children: `${name.split(' ')?.[0]?.[0].toUpperCase()}${name.split(' ')?.[1]?.[0]}`
  };
}

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { activeMenu } = useSelector((state) => state.menu);
  const { userDetails } = useSelector((state) => state.user);
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    menuItems.items[0]?.children?.map((item) => {
      if (location.pathname?.split('/')?.includes(item.url.split('/')[1])) {
        dispatch(setActiveMenu({ activeMenu: item?.title === 'Home' ? 'Dashboard' : item?.title }));
      }
    });
  }, [location.pathname]);

  return (
    <>
      {/* {!matchesXs && <Search />} */}
      <Typography
        variant="body1"
        color="secondary"
        sx={{ ml: 2, width: '100%' }}
      >
        {activeMenu}
      </Typography>
      {!matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {/* <IconButton
        component={Link}
        href="https://github.com/codedthemes/mantis-free-react-admin-template"
        target="_blank"
        disableRipple
        color="secondary"
        title="Download Free Version"
        sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
      >
        <GithubOutlined />
      </IconButton> */}

      {/* <Notification /> */}
      <Box
        sx={{
          height: '37px',
          width: '37px',
          borderRadius: '50%',
          background: theme.palette.primary.light,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: '16px'
        }}
      >
        {userDetails?.profile_image?.length > 1 ? (
          <Avatar
            src={userDetails?.profile_image}
            alt={`${userDetails?.first_name[0].toUpperCase()} ${userDetails?.last_name[0]}`}
            sx={{ bgcolor: 'secondary', fontSize: '15px', color: 'black', width: 37, height: 37 }}
          />
        ) : (
          <Avatar
            {...stringAvatar(`${userDetails?.first_name[0].toUpperCase()} ${userDetails?.last_name[0]}`)}
            // alt={stringAvatar(`${row?.name}`)}
            sx={{ bgcolor: 'secondary', fontSize: '15px', color: 'black', width: 37, height: 37 }}
          />
        )}
      </Box>
      {/* {!matchesXs && <Profile /> && <MobileSection /> } */}
      {matchesXs}
    </>
  );
};

export default HeaderContent;
