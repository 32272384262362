export const PASSWORD_REGEX = /^(?=.*[a-zA-Z0-9])(?=.*[0-9]).{6,}$/;
// /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/ /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/
export const SUPPORT_IMAGE_TYPE = ['image/png', 'image/jpeg', 'image/jpg'];
export const SUPPORT_XLSX_FILE = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
export const SUPPORT_EMAIL = 'support@turnkeysr.com';
export const COMPANY_URL_SUFFIX = '.opstgs.com';
export const COMPANY_NAME = 'opstgs';
export const ROLE = {
  superAdmin: 'super_admin',
  admin: 'admin'
};

export const DEFAULT_PAGE_SIZE = 10;

export const REACT_APP_ADMIN_API_ENDPOINT = 'admin/v1/';
export const REACT_APP_TENANT_API_ENDPOINT = 'tenant/v1/';
export const REACT_APP_COMMON_ENDPOINT = 'api/v1/';

export const maxFileSizeAllow = 5; //mb

export const fiscalYearValue = {
  1: {
    period: 'January - December'
  },
  4: {
    period: 'April - March'
  },
  7: {
    period: 'July - June'
  },
  10: {
    period: 'October - September'
  }
};
