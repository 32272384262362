import React from 'react';
// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import 'App.css';
import { ToastContainer } from 'react-toastify';
import * as storage from 'helpers/storageRequests';
import { useDispatch } from 'react-redux';
import { fetchUserDetails } from 'store/reducers/user';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const userAuth = storage.getRefreshAuth();
    (async () => {
      try {
        const { error, payload } = userAuth && (await dispatch(fetchUserDetails()));
      } catch (error) {}
    })();
  }, []);
  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
        <ToastContainer
          position="top-right"
          autoClose={7000}
        />
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;
