// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme) {
  const disabledStyle = {
    '&.MuiButton-containedPrimary': {
      '&.Mui-disabled': {
        opacity: '50%',
        backgroundColor: theme.palette.primary.main
      }
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200]
    }
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: '6px',
          padding: '5px 16px',
          fontSize: '14px',
          lineHeight: '24px'
        },
        contained: {
          ...disabledStyle
        },
        outlined: {
          ...disabledStyle
        }
      }
    }
  };
}
