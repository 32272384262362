// material-ui
import { Box, Typography, Divider } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import LogoutConfirmation from 'components/@extended/LogoutConfirmation';
import { useState } from 'react';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const [isOpenLogoutModal, setOpenLogoutModal] = useState(false);

  const navGroups = menuItem.items.map((item, index) => {
    switch (item.type) {
      case 'group':
        return (
          <>
            <NavGroup
              key={item.id}
              item={item}
              setOpenLogoutModal={setOpenLogoutModal}
            />
            {menuItem.items?.length !== index + 1 && <Divider sx={{ maxWidth: '80%', opacity: 0.5, margin: 'auto' }} />}
          </>
        );
      default:
        return (
          <Typography
            key={item.id}
            variant="h6"
            color="error"
            align="center"
          >
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box sx={{ pt: 5, flexGrow: 1 }}>
      {navGroups}

      <LogoutConfirmation
        isOpenModal={isOpenLogoutModal}
        setOpenModal={setOpenLogoutModal}
      />
    </Box>
  );
};

export default Navigation;
